import React, { useContext, useEffect } from "react";
import { Typography, Modal, Button, MenuItem, Select } from "@mui/material";
import "./SettingsDialog.css";
import { State } from "../../State";

export const SettingsDialog = (props) => {
  const { settings, setSettings } = useContext(State);
  useEffect(() => {
    localStorage.setItem("normalization", settings.normalization);
    localStorage.setItem(
      "acceptable_disease_likelihood",
      settings.acceptable_disease_likelihood
    );
    localStorage.setItem("rounding", settings.round_result_percentage);
  }, [settings]);

  const clearCacheReload = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((name) => caches.delete(name)));
      window.location.reload();
    }
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <div className="settingsDialogContainer">
        <Typography>
          <strong>Einstellungen:</strong>
        </Typography>
        <div className="settingBlock">
          <Typography>Normalisierung</Typography>
          <Select
            id="normalization-select"
            value={settings.normalization}
            label="Normalisierung"
            onChange={(event) => {
              setSettings((settings) => ({
                ...settings,
                normalization: event.target.value,
              }));
            }}
          >
            <MenuItem value={"normal"}>Normal</MenuItem>
            <MenuItem value={"top"}>Top</MenuItem>
            <MenuItem value={"avg"}>Average</MenuItem>
          </Select>
        </div>
        <div className="settingBlock">
          <Typography>Runden</Typography>

          <Select
            id="rounding-select"
            value={settings.round_result_percentage}
            label="Runden"
            onChange={(event) => {
              setSettings((settings) => ({
                ...settings,
                round_result_percentage: event.target.value,
              }));
            }}
          >
            <MenuItem value={"true"}>Ja</MenuItem>
            <MenuItem value={"false"}>Nein</MenuItem>
          </Select>
        </div>
        <div className="settingBlock">
          <Typography>Krankheitswahrscheinlichkeit</Typography>

          <Select
            id="threshold-select"
            value={settings.acceptable_disease_likelihood}
            label="Krankheitswahrscheinlichkeit"
            onChange={(event) => {
              setSettings((settings) => ({
                ...settings,
                acceptable_disease_likelihood: event.target.value,
              }));
            }}
          >
            <MenuItem value={"0.01"}>1%</MenuItem>
            <MenuItem value={"0.05"}>5%</MenuItem>
            <MenuItem value={"0.1"}>10%</MenuItem>
            <MenuItem value={"0.15"}>15%</MenuItem>
            <MenuItem value={"0.2"}>20%</MenuItem>
          </Select>
        </div>
        <Button variant="contained" color="primary" onClick={clearCacheReload}>
          Cache leeren / Aktualisieren
        </Button>
      </div>
    </Modal>
  );
};
