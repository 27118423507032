import React from "react";
import PetsIcon from "@mui/icons-material/Pets";
import PropTypes from "prop-types";

export const ChosePetIcon = (props) => {
  return (
    <PetsIcon
      sx={{
        color: props.active ? "secondary.main" : "primary.main",
        fontSize: "2rem",
      }}
    />
  );
};

ChosePetIcon.propTypes = {
  active: PropTypes.bool,
};
