/* eslint-disable no-constant-condition */
import ChoosePet from "../choosePet/ChoosePet";
import Anatomy from "../anatomy/Anatomy";

import "./Body.css";

import { ExtraSymptom } from "../extraSymptom/ExtraSymptom";
import { ExtraSymptomIcon } from "../extraSymptom/ExtraSymptomIcon";
import { ExtraSymptomLabel } from "../extraSymptom/ExtraSymptomLabel";
import { Symptoms } from "../symptoms/Symptoms";

import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useWindowSize } from "../../utils/HelperFunctions";
import { StartOverIcon } from "../startOver/StartOverIcon";
import { StartOver } from "../startOver/StartOver";
import React, { useContext } from "react";
import { ChosePetLabel } from "../choosePet/ChoosePetLabel";
import { AnatomyLabel } from "../anatomy/AnatomyLabel";
import { ChosePetIcon } from "../choosePet/ChoosePetIcon";
import { AnatomyIcon } from "../anatomy/AnatomyIcon";
import { SymptomIcon } from "../symptoms/SymptomIcon";
import { SymptomLabel } from "../symptoms/SymptomLabel";
import { State } from "../../State";
import { SummaryLabel } from "../summary/SummaryLabel";
import { SummaryIcon } from "../summary/SummaryIcon";
import { Summary } from "../summary/Summary";
import { StartOverLabel } from "../startOver/StartOverLabel";

function Body(props) {
  const { isMD } = useWindowSize();

  const {
    animalType,
    animalSectionOpened,
    setAnimalSectionOpened,
    setAnimalSectionAnswered,
    regionSectionOpened,
    setRegionSectionOpened,
    regionSectionAnswered,
    setRegionSectionAnswered,
    questionSectionOpen,
    setQuestionSectionOpen,
    questionSectionAnswered,
    setQuestionSectionAnswered,
    extraQuestionSectionOpen,
    setExtraQuestionSectionOpen,
    summarySectionOpened,
    setSummarySectionOpened,
    startOverSectionOpened,
    setStartOverSectionOpened,
  } = useContext(State);

  const renewSections = () => {
    setAnimalSectionOpened(true);
    setAnimalSectionAnswered(false);

    setRegionSectionOpened(false);
    setRegionSectionAnswered(false);

    setQuestionSectionOpen(false);
    setQuestionSectionAnswered(false);

    setExtraQuestionSectionOpen(false);
    setSummarySectionOpened(false);
    setStartOverSectionOpened(false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: isMD ? "1rem" : "1rem 8rem",
      }}
    >
      <Stepper orientation="vertical" sx={{ ml: 1 }}>
        <Step key={0} active={animalSectionOpened}>
          <StepLabel
            icon={<ChosePetIcon active={animalSectionOpened} />}
            onClick={() => {
              setAnimalSectionOpened(!animalSectionOpened);
            }}
            sx={{ cursor: "pointer !important" }}
          >
            <ChosePetLabel />
          </StepLabel>
          <StepContent>
            <ChoosePet renewSections={renewSections}/>
          </StepContent>
        </Step>

        <Step key={1} active={regionSectionOpened}>
          <StepLabel
            icon={<AnatomyIcon active={regionSectionOpened} />}
            onClick={() => {
              if (animalType) {
                setRegionSectionOpened(!regionSectionOpened);
              }
            }}
            sx={{ cursor: "pointer !important" }}
          >
            <AnatomyLabel />
          </StepLabel>
          <StepContent>
            <Anatomy />
          </StepContent>
        </Step>

        <Step key={2} active={questionSectionOpen}>
          <StepLabel
            icon={<SymptomIcon active={questionSectionOpen} />}
            onClick={() => {
              if (regionSectionAnswered) {
                setQuestionSectionOpen(!questionSectionOpen);
              }
            }}
            sx={{ cursor: "pointer !important" }}
          >
            <SymptomLabel />
          </StepLabel>
          <StepContent>
            <Symptoms />
          </StepContent>
        </Step>

        <Step key={3} active={extraQuestionSectionOpen}>
          <StepLabel
            icon={<ExtraSymptomIcon active={extraQuestionSectionOpen} />}
            onClick={() => {
              if (questionSectionAnswered) {
                setExtraQuestionSectionOpen(!extraQuestionSectionOpen);
              }
            }}
            sx={{ cursor: "pointer !important" }}
          >
            <ExtraSymptomLabel />
          </StepLabel>
          <StepContent>
            <ExtraSymptom />
          </StepContent>
        </Step>

        <Step key={4} active={summarySectionOpened}>
          <StepLabel
            icon={<SummaryIcon active={summarySectionOpened} />}
            onClick={() => {}}
            sx={{ cursor: "pointer !important" }}
          >
            <SummaryLabel />
          </StepLabel>
          <StepContent>
            <Summary />
          </StepContent>
        </Step>

        <Step key={5} active={startOverSectionOpened}>
          <StepLabel
            icon={
              <StartOverIcon
                sx={{
                  color: false ? "secondary.main" : "primary.main",
                  fontSize: "2rem",
                }}
              />
            }
            onClick={() => {
              setStartOverSectionOpened(!startOverSectionOpened);
            }}
            sx={{ cursor: "pointer !important" }}
          >
            <StartOverLabel />
          </StepLabel>
          <StepContent>
            <StartOver
              renewSections={renewSections}
              setStartOverSectionOpened={setStartOverSectionOpened}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}

export default Body;
