import { createContext } from "react";

export const Regions = Object.freeze({
  head: 27,
  back: 28,
  chest: 26,
  belly: 23,
  legs: 25,
  skin: 24,
  all: 29,
});

export const FrequentSymptomsDog = {
  8771: {answer: "?", name: 'Atemnot (Dyspnoe)', link: 'https://petsvetcheck.de/symptom-hund/atemnot-dyspnoe/', orderNumber: 0},
  8922: {answer: "?", name: 'Durchfall', link: 'https://petsvetcheck.de/symptom-hund/durchfall/', orderNumber: 1},
  10699: {answer: "?", name: 'Erbrechen', link: 'https://petsvetcheck.de/symptom-hund/erbrechen/', orderNumber: 2},
  10965: {answer: "?", name: 'Schwäche (Lethargie)', link: 'https://petsvetcheck.de/symptom-hund/schwaeche-lethargie/', orderNumber: 3},
  8761: {answer: "?", name: 'Appetit reduziert oder Appetitlosigkeit (Anorexie)', link: 'https://petsvetcheck.de/symptom-hund/appetit-reduziert-oder-appetitlosigkeit-anorexie/', orderNumber: 4},
  8828: {answer: "?", name: 'Belastbarkeit reduziert', link: 'https://petsvetcheck.de/symptom-hund/belastbarkeit-reduziert/', orderNumber: 5},
  9998: {answer: "?", name: 'Husten', link: 'https://petsvetcheck.de/symptom-hund/husten/', orderNumber: 6}
};

export const FrequentSymptomsCat = {
  16060: {answer: "?", name: 'Fieber', link: 'https://petsvetcheck.de/symptom-katze/fieber/', orderNumber: 0},
  16714: {answer: "?", name: 'Schmerzen', link: 'https://petsvetcheck.de/symptom-katze/schmerzen/', orderNumber: 1},
  16276: {answer: "?", name: 'Juckreiz', link: 'https://petsvetcheck.de/symptom-katze/juckreiz/', orderNumber: 2},
  16263: {answer: "?", name: 'Husten', link: 'https://petsvetcheck.de/symptom-katze/husten/', orderNumber: 3},
  16034: {answer: "?", name: 'Erbrechen', link: 'https://petsvetcheck.de/symptom-katze/erbrechen/', orderNumber: 4},
  16003: {answer: "?", name: 'Durchfall w\u00e4ssrig, schleimig oder blutig', link: 'https://petsvetcheck.de/symptom-katze/durchfall-waessrig-schleimig-oder-blutig/', orderNumber: 5},
  15836: {answer: "?", name: 'Appetit reduziert oder Appetitlosigkeit (Anorexie)', link: 'https://petsvetcheck.de/symptom-katze/appetit-reduziert-oder-appetitlosigkeit-anorexie/', orderNumber: 6},
  15837: {answer: "?", name: 'Atembeschwerden oder Atemnot (Dyspnoe)', link: 'https://petsvetcheck.de/symptom-katze/atembeschwerden-oder-atemnot-dyspnoe/', orderNumber: 7},
  15837: {answer: "?", name: 'Atemfrequenz erh\u00f6ht (Tachypnoe)', link: 'https://petsvetcheck.de/symptom-katze/atemfrequenz-erhoeht-tachypnoe/', orderNumber: 8},
  15822: {answer: "?", name: 'Allgemeine Schwäche (Lethargie)', link: 'https://petsvetcheck.de/symptom-katze/allgemeine-schwaeche-lethargie/', orderNumber: 9},
  15907: {answer: "?", name: 'Belastbarkeit reduziert', link: 'https://petsvetcheck.de/symptom-katze/belastbarkeit-reduziert/', orderNumber: 10},
};

export const State = createContext(null);
