import React from "react";
import "./Logo.css";
import headerImage from "../assets/header-image.png";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useWindowSize } from "../../utils/HelperFunctions";
import petsvetIcon from "../assets/Petsvet-icon-eye.png";

function Logo(props) {
  const { isMobileDevice } = useWindowSize();
  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: "4.5em",
        bgcolor: "#f5f5f5",
      }}
    >
      <Grid container spacing={1}>
        {/* LOGO */}
        <Grid
          item
          sx={{
            display: isMobileDevice ? "none" : "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          xs={2}
        >
          <img
            src={petsvetIcon}
            alt="Petsvet icon"
            style={{
              margin: "1em",
              height: isMobileDevice ? "4em" : "5.5em",
              width: isMobileDevice ? "4em" : "5.5em",
            }}
          />
        </Grid>
        {/*TEXT*/}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          xs={6}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              marginLeft: isMobileDevice ? "1em" : 0,
            }}
          >
            <Typography
              sx={{
                fontSize: isMobileDevice ? 30 : 48,
                color: "primary",
                fontFamily: "Poppins, Roboto, sans-serif",
                fontWeight: 900,
              }}
            >
              <i style={{ marginRight: ".1em", color: "#0583F2" }}>PETS</i>
              VETCHECK
            </Typography>
            <Typography
              sx={{
                fontSize: isMobileDevice ? 14 : 18,
                fontWeight: 600,
                fontFamily: "Inter, Roboto, sans-serif",
              }}
            >
              Gesundheit für Hund und Katze
            </Typography>
          </Box>
        </Grid>
        {isMobileDevice ? (
          <>
            <Grid item xs={4} />
            <Grid
              item
              component={Box}
              xs={2}
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "flex-start",
              }}
            >
              <img
                src={headerImage}
                alt="Icon of dog"
                style={{ height: "100%" }}
              />
            </Grid>
          </>
        ) : (
          <Grid
            item
            component={Box}
            xs={2}
            sx={{
              display: "flex",
              direction: "row",
              alignItems: "flex-start",
            }}
          >
            <img
              src={headerImage}
              alt="Icon of dog"
              style={{ height: "100%" }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Logo;
