import React from "react";
import { Typography } from "@mui/material";
import "./SymptomLabel.css";

export const SymptomLabel = () => {
  return (
    <Typography
      sx={{
        fontSize: "1.2rem",
        fontWeight: false ? "bold" : null,
        color: false ? "secondary.main" : "primary.main",
      }}
    >
      <span className="non-selectable">Erste Fragen</span>
    </Typography>
  );
};
