import React from "react";
import { Typography } from "@mui/material";
import "./ExtraSymptomLabel.css";

export const ExtraSymptomLabel = () => {
  return (
    <Typography
      sx={{
        fontSize: "1.2rem",
        fontWeight: false ? "bold" : null,
        color: false ? "secondary.main" : "primary.main",
      }}
    >
      <span className="non-selectable">Folgefragen</span>
    </Typography>
  );
};
