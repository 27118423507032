import React from "react";
import PropTypes from "prop-types";
import BorderColorIcon from "@mui/icons-material/BorderColor";

export const SymptomIcon = (props) => {
  return (
    <BorderColorIcon
      sx={{
        color: props.active ? "secondary.main" : "primary.main",
        fontSize: "2rem",
      }}
    />
  );
};

SymptomIcon.propTypes = {
  active: PropTypes.bool,
};
