import React, { useContext } from "react";
import petsvetIcon from "../assets/Petsvet-icon-eye.png";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { MenuIcon } from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import { SettingsDialog } from "../dialog/SettingsDialog";
import "./Navbar.css";
import { styled } from "@mui/material/styles";

const pages = [
  "Home",
  "Notfälle",
  "Symptomechecker",
  "Symptome a-z",
  "Krankheiten a-z",
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);

  const showSettingsDialog = () => {};
  return (
    <AppBar position="static" sx={{}}>
      <Toolbar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            alignContent: "center",
            gap: "1em",
          }}
        >
          <img
            src={petsvetIcon}
            alt="Petsvet icon"
            style={{ width: "2.5em" }}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              display: "flex",
              fontWeight: 700,
              color: "inherit",
              textDecoration: "none",
              fontFamily: "Inter, Roboto, sans-serif",
            }}
          >
            <i style={{ marginRight: ".1em", color: "#0583F2" }}>PETS</i>
            VETCHECK
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <IconButton
            onClick={() => {
              setSettingsDialogOpen(true);
            }}
            aria-label="settings"
          >
            <SettingsIcon className="settingsIcon" />
          </IconButton>
        </Box>
        <SettingsDialog
          isOpen={settingsDialogOpen}
          onClose={() => {
            setSettingsDialogOpen(false);
          }}
        ></SettingsDialog>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
