import React, { useEffect, useState, useContext } from "react";
import {
  Modal,
  Typography,
  Button,
  CircularProgress,
  Container,
  Link
} from "@mui/material"; // Assuming you're using Material-UI
import "./SymptomInfoDialog.css";
import { State } from "../../State";

const apiUrl = process.env.REACT_APP_API_URL;

const sendFetchRequest = (symptomId, animalType, setSymptomDescription) => {
  setSymptomDescription("LOADING");

  fetch(`${apiUrl}/v1/symptom`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      animalType, 
      symptomId,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      setSymptomDescription(data.description);
    })
    .catch((error) => console.error("Error:", error));
};

export const SymptomInfoDialog = ({ open, onClose, symptom }) => {
  const [symptomDescription, setSymptomDescription] = useState("");
  const { animalType } = useContext(State);
  useEffect(() => {
    if (symptom && symptom.id) {
      sendFetchRequest(symptom.id, animalType, setSymptomDescription);
    }
  }, [symptom]);

  return (
    <Modal open={open} onClose={onClose}>
      <Container className="infoDialogContainer">
        <Typography sx={{ fontWeight: "bold", mb: "1em" }}>
          {symptom?.name ?? ''}
        </Typography>
        <div className="infoDialogDescriptionBlock">
          {symptomDescription === "LOADING" ? (
            <CircularProgress />
          ) : (
            <Typography>{symptomDescription}</Typography>
          )}
        </div>
        {symptom?.link && <div className="infoDialogLink">
          <Link  href={symptom.link} target="_blank" rel="noopener">
            <Typography sx={{ fontWeight: "bold", mb: "1em" }}>
               Mehr lesen bei petsvetcheck.de
            </Typography>
          </Link>
        </div>}
        <div className="infoDialogButtonBlock">
          <Button variant="contained" color="primary" onClick={onClose}>
            Schließen
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
