import React from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PropTypes from "prop-types";

export const StartOverIcon = (props) => {
  return (
    <RestartAltIcon
      sx={{
        color: props.active ? "secondary.main" : "primary.main",
        fontSize: "2rem",
      }}
    />
  );
};

RestartAltIcon.propTypes = {
  active: PropTypes.bool,
};
