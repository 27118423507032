import { useLayoutEffect, useState } from "react";

export const earlyStopping = (likelihood) => {
  const likelihoodThreshold = parseFloat(
    localStorage.getItem("early_stopping_threshold")
  );
  return likelihood < likelihoodThreshold;
};

export const singleDiseaseCandidate = (diseaseCandidates) => {
  const thresHold = parseFloat(
    localStorage.getItem("acceptable_disease_likelihood")
  );
  return (
    Object.entries(diseaseCandidates)
      .sort(([, a], [, b]) => b - a)
      .filter(([, value]) => value > thresHold).length === 1
  );
};

const filterOutAnsweredSymptoms = (symptomCandidates, answeredQuestions) => {
  const newSymptomCandidates = { ...symptomCandidates };

  for (const [disease, value] of answeredQuestions.entries()) {
    if (value === "n") {
      delete newSymptomCandidates[disease];
    }
  }

  return newSymptomCandidates;
};

export const getTopCandidate = (symptomCandidates) => {
  return Object.entries(symptomCandidates).reduce((max, [id, obj]) =>
    max.probability > obj.probability ? max : {...obj, id},
    { probability: -Infinity }
  );
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return {
    windowSize,
    isMobileDevice: windowSize[0] < 900,
    isMD: windowSize[0] < 1200,
  };
};
