import React, { useEffect, useContext, useState } from "react";
import "./Summary.css";
import {
  getTopCandidate,
  earlyStopping,
  singleDiseaseCandidate,
} from "../../utils/HelperFunctions";
import {
  Grid,
  Typography,
} from "@mui/material";
import { State } from "../../State";
import { Disease } from "../disease/Disease";
import { DiseaseInfoDialog } from "../dialog/DiseaseInfoDialog";

function roundToClosestFive(num) {
  return (Math.round((num * 100) / 5) * 5) / 100;
}

export const Summary = () => {
  const {
    animalType,
    selectedRegions,
    regionSectionAnswered,
    initialQuestions,
    extraQuestions,
    questionSectionAnswered,
    setNextQuestion,
    setSymptomCandidates,
    setSymptom2diseasesLow,
    setSymptom2diseasesMedium,
    setSymptom2diseasesHigh,
    setDiseaseCandidates,
    setSummaryUpdateInProgress,
    setTopKProbability,
    settings,
  } = useContext(State);

  const [correctedCandidates, setCorrectedCandidates] = useState({});
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [currentDisease, setCurrentDisease] = useState();

  const getAcceptableCandidates = (diseaseCandidates) => {
    if (!diseaseCandidates) {
      return [];
    }


    const thresHold = parseFloat(settings.acceptable_disease_likelihood);
    

      return Object.entries(diseaseCandidates)
      .sort(([, a], [, b]) => b.probability - a.probability)
      .filter(([, obj]) => obj.probability > thresHold)
      .map(([key, obj]) => ({ id: key, name: obj.name, probability: obj.probability, link: obj.link}));
  };

  const correctLikelihood = (ld, topKProbability, iterNo) => {
    const tailProbability = (100 - Math.min(iterNo + 24, 95)) / 100;
    let correctedLd = (ld / topKProbability) * (1 - tailProbability);
    return { correctedLd, tailProbability };
  };

  const getTopKCorrectedDiseases = (
    disease2likelihood,
    k,
    iterNo,
    topKProbability,
  ) => {

    const relevantDiseases = 0;
    const convergenceProbability = 10;
    const result = {};
    var i = 0;
    var ld;

    const disease2likelihoodSortedEntries = Object.entries(disease2likelihood).sort((a, b) => b[1].probability - a[1].probability);

    for (let disease of disease2likelihoodSortedEntries) {
      if (i === k) {
        break;
      }

      ld = disease[1].probability;
      const { correctedLd, tailProbability } = correctLikelihood(
        ld,
        topKProbability,
        iterNo
      );
      if (ld >= convergenceProbability && tailProbability < 0.5) {
        relevantDiseases++;
      }

      result[disease] = {
        ...disease2likelihood[disease[0]],
        probability: correctedLd
      };
      i++;
    }

    return relevantDiseases === 1 && result.size > 0
      ? { [Object.keys(result)[0]]: Object.values(result)[0] }
      : result;
  };

  const updateInitialState = (questions) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const symptomsPresent = Object.keys(questions).filter(
      (key) => questions[key].answer === 'y'
    );

    const symptomsAbsent = Object.keys(questions).filter(
      (key) => questions[key].answer === 'n'
    );

    const symptomsUnknown = Object.keys(questions).filter(
      (key) => questions[key].answer === '?'
    );

    fetch(`${apiUrl}/v1/initialize_v2`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        animal: animalType,
        regions: Array.from(selectedRegions),
        initial_symptoms_present: symptomsPresent,
        initial_symptoms_absent: symptomsAbsent,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const symptomCandidates = Object.fromEntries(
          Object.entries(data.symptom_candidates).filter(
            ([key]) => !symptomsUnknown.includes(key)
          )
        );

        setSymptomCandidates(data.symptom_candidates);
        setDiseaseCandidates(data.disease_candidates);
        setSymptom2diseasesLow(data.symptom2diseases_low);
        setSymptom2diseasesMedium(data.symptom2diseases_medium);
        setSymptom2diseasesHigh(data.symptom2diseases_high);
        setSummaryUpdateInProgress(false);
        setTopKProbability(data.top_k_probability);

        if (settings.normalization == "avg") {
          setCorrectedCandidates(data.normalized_likelihood_avg);
        } else if (settings.normalization == "top") {
          const correctedDC = getTopKCorrectedDiseases(
            data.disease_candidates,
            5,
            symptomsPresent.length +
              symptomsAbsent.length +
              symptomsUnknown.length,
            data.top_k_probability
          );
          setCorrectedCandidates(correctedDC);
        } else {
          setCorrectedCandidates(data.normalized_likelihood_norm);
        }
        const nq = getTopCandidate(symptomCandidates);

        if (
          !earlyStopping(symptomCandidates[nq]) &&
          !singleDiseaseCandidate(symptomCandidates)
        ) {
          setNextQuestion(nq);
        } else {
          setNextQuestion("NONE");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const getDetailedInfo =
    () => `Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id dignissim
  quam. Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat. Aliquam eget maximus est, id
  dignissim quam.`;

  useEffect(() => {
    if (regionSectionAnswered && questionSectionAnswered) {
      setSummaryUpdateInProgress(true);

      updateInitialState({...initialQuestions, ...extraQuestions});
    }
  }, [animalType, selectedRegions, initialQuestions, extraQuestions, settings]);

  const acceptableCandidates = getAcceptableCandidates(correctedCandidates, 50);

  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>
        Mögliche Erkrankungen und Störungen:
      </Typography>
      <Grid container spacing={1}>
        {acceptableCandidates.map((candidate, index) => (
          <Disease
            name={candidate.name}
            id = {candidate.id}
            link = {candidate.link}
            value={
              settings.round_result_percentage === "true"
                ? roundToClosestFive(candidate.probability)
                : candidate.probability
            }
            setInfoDialogDisease={setCurrentDisease}
            setInfoDialogOpen={setInfoDialogOpen}
            key={index}
          />
        ))}
      </Grid>
      {acceptableCandidates.length === 0 && (
        <Grid item sm={12} md={9} lg={8} xl={8}>
          <Typography sx={{ width: "100%", flexShrink: 0, marginTop: "1em" }}>
            {"Benötigen Sie weitere Informationen"}
          </Typography>
        </Grid>
      )}
      <DiseaseInfoDialog
        open={infoDialogOpen}
        disease={currentDisease}
        onClose={() => {
          setInfoDialogOpen(false);
        }}
      />
    </>
  );
};

export default Summary;
