import React from "react";
import { Typography } from "@mui/material";
import "./StartOverLabel.css";

export const StartOverLabel = () => {
  return (
    <Typography
      sx={{
        fontSize: "1.2rem",
        fontWeight: false ? "bold" : null,
        color: false ? "secondary.main" : "primary.main",
      }}
    >
      Von vorne anfangen
    </Typography>
  );
};
