import React, { useContext } from "react";
import "./ChoosePet.css";
import dog from "../assets/Dog.png";
import cat from "../assets/Cat.png";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useWindowSize } from "../../utils/HelperFunctions";
import { State, FrequentSymptomsDog, FrequentSymptomsCat} from "../../State";

function ChoosePet(props) {
  const { isMD } = useWindowSize();
  const {
    animalType,
    setAnimalType,
    setAnimalSectionOpened,
    setAnimalSectionAnswered,
    setRegionSectionOpened,
    setInitialQuestions,
    resetState
  } = useContext(State);

  const onAnimalClick = () => {
    setAnimalSectionOpened(false);
    setAnimalSectionAnswered(true);
    setRegionSectionOpened(true);
  };

  const onDogClick = () => {
    resetState()
    props.renewSections()
    setAnimalType("dog");
    setInitialQuestions(FrequentSymptomsDog)
    onAnimalClick();
  };
  
  const onCatClick = () => {
    resetState()
    props.renewSections()
    setAnimalType("cat");
    setInitialQuestions(FrequentSymptomsCat)
    onAnimalClick();
  };

  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>
        {"Suchen sie Hilfe für ihren Hund oder ihre Katze ?"}
      </Typography>
      <Grid container>
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1 }} onClick={onDogClick}>
            <Box
              sx={{
                p: 3,
                height: isMD ? "6rem" : "8rem",
                width: isMD ? "6rem" : "8rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                textTransform: "none",
                backgroundColor:
                  animalType === "dog" ? "secondary.main" : "primary.main",
                boxShadow: "",
                cursor: "pointer",
              }}
            >
              <img src={dog} alt="Icon of dog" />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: isMD ? "1.2rem" : "1.5rem",
                }}
              >
                Hund
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Divider orientation="vertical" flexItem>
          Oder
        </Divider>

        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ m: 1, position: "relative" }} onClick={onCatClick}>
            <Box
              sx={{
                p: 3,
                height: isMD ? "6rem" : "8rem",
                width: isMD ? "6rem" : "8rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "1rem",
                textTransform: "none",
                backgroundColor:
                  animalType === "cat" ? "secondary.main" : "primary.main",
                boxShadow: "",
                cursor: "pointer",
              }}
            >
              <img src={cat} alt="Icon of cat" />
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: isMD ? "1.2rem" : "1.5rem",
                }}
              >
                Katze
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ChoosePet;
