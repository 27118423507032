import React from "react";
import "./Question.css";
import styled from "styled-components";
import theme from "../../Theme";

import { IconButton, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import PropTypes from "prop-types";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export const Question = (props) => {
  return (
    <div className="margin">
      <div className={"questionLabel"}>
        <Typography>
          Beobachten sie <strong>{props.questionName}</strong>{" "}
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => {
              if (props.setInfoDialogSymptom && props.setInfoDialogOpen)
                props.setInfoDialogSymptom({id: props.questionId, name: props.questionName, link: props.link});
              props.setInfoDialogOpen(true);
            }}
          >
            <HelpIcon />
          </IconButton>
        </Typography>
      </div>

      <ToggleButtonGroup key={props.questionId} value={props.value}>
        <StyledToggleButton
          variant="contained"
          onClick={props.setAnswer}
          value={"y"}
          sx={{
            backgroundColor: props.value === "y" ? "primary.main" : "inherit",
            textTransform: "none",
            width: "6rem",
            border: "1px solid white",
          }}
        >
          Ja
        </StyledToggleButton>

        <StyledToggleButton
          variant="contained"
          onClick={props.setAnswer}
          value={"?"}
          sx={{
            backgroundColor: props.value === "?" ? "green" : "inherit",
            textTransform: "none",
            width: "6rem",
            border: "1px solid white",
          }}
        >
          Unklar
        </StyledToggleButton>

        <StyledToggleButton
          variant="contained"
          onClick={props.setAnswer}
          value={"n"}
          sx={{
            textTransform: "none",
            width: "6rem",
            border: "1px solid white",
          }}
        >
          Nein
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

Question.propTypes = {
  value: PropTypes.string.isRequired,
  setAnswer: PropTypes.func.isRequired,
};

const StyledToggleButton = styled(ToggleButton)`
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${theme.palette.primary.main};
    color: white;
  }
`;
