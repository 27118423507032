import React, { Fragment, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

import "./ExtraSymptom.css";
import {
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { State } from "../../State";
import { Question } from "../question/Question";
import WarningIcon from "@mui/icons-material/Warning";
import { SymptomInfoDialog } from "../dialog/SymptomInfoDialog";

export const ExtraSymptom = (props) => {
  const {
    symptomCandidates,
    extraQuestions,
    setExtraQuestions,
    nextQuestion,
    setNextQuestion,
    extraQuestionsAnswered,
    setExtraQuestionsAnswered,
    isDebugMode,
  } = useContext(State);

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogSymptom, setInfoDialogSymptom] = useState(null);
  const sortedExtraQuestionEntries = Object.entries(extraQuestions).sort(([, a], [, b]) => a.orderNumber - b.orderNumber);

  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>
        Könnten Sie bitte zusätzliche Informationen zu den Symptomen geben?
      </Typography>
      <Margin />
      {sortedExtraQuestionEntries.map(([id, { answer, name, link }]) => (
        <Question
          setInfoDialogSymptom={setInfoDialogSymptom}
          setInfoDialogOpen={setInfoDialogOpen}
          key={id}
          questionName={name}
          questionId={id}
          link={link}
          value={answer}
          setAnswer={(e) => {
            setExtraQuestions({ ...extraQuestions, [id]: { ...extraQuestions[id], answer: e.target.value } });
          }}
          disabled={false}
        />
      ))}
      <Typography>
        {nextQuestion && nextQuestion !== "NONE" && (
          <strong>Neue Frage:</strong>
        )}
      </Typography>
      {nextQuestion && nextQuestion !== "NONE" ? (
        <Question
          setInfoDialogSymptom={setInfoDialogSymptom}
          setInfoDialogOpen={setInfoDialogOpen}
          questionName={nextQuestion.name}
          questionId={nextQuestion.id}
          link={nextQuestion.link}
          value={"?"}
          setAnswer={(e) => {
            if (!extraQuestionsAnswered) {
              setExtraQuestionsAnswered(true);
            }
            setExtraQuestions({...extraQuestions, [nextQuestion.id]:  {...nextQuestion, answer: e.target.value,  orderNumber: Object.keys(extraQuestions).length}});
            setNextQuestion(null);
          }}
          disabled={false}
        />
      ) : nextQuestion === "NONE" ? (
        <WarningBlock>
          <WarningIcon />
          <Typography>
            Es gibt keine weiteren Fragen. Schauen Sie sich die Ergebnisse unten
            an.
          </Typography>
        </WarningBlock>
      ) : (
        <StyledCircularProgress />
      )}
      {/** You can use "Tooltip"/"popover" component from the library to show info on Hoover/click, just wrap the IconButton inside a Tooltip/popover */}
      {isDebugMode() && nextQuestion && nextQuestion !== "NONE" && (
        <DebugBlock>
          <Typography>likelihood:</Typography>
          <Typography>{symptomCandidates[nextQuestion]}</Typography>
          <IconButton
            variant="contained"
            color="primary"
            onClick={() => {
              if (symptomCandidates && nextQuestion) {
                alert(
                  `early stopping threshold: ${localStorage.getItem(
                    "early_stopping_threshold"
                  )}`
                );
              }
            }}
          >
            <HelpIcon />
          </IconButton>
        </DebugBlock>
      )}
      <Divider sx={{ mt: 1.5, mb: 1.5 }}></Divider>
      <SymptomInfoDialog
        open={infoDialogOpen}
        symptom={infoDialogSymptom}
        onClose={() => {
          setInfoDialogOpen(false);
        }}
      />
    </>
  );
};

const DebugBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
`;

const WarningBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
`;

const Margin = styled.div`
  margin: 1em;
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: 1em;
`;
