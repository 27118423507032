import React from "react";
import MedicationIcon from "@mui/icons-material/Medication";
import PropTypes from "prop-types";

export const SummaryIcon = (props) => {
  return (
    <MedicationIcon
      sx={{
        color: props.active ? "secondary.main" : "primary.main",
        fontSize: "2rem",
      }}
    />
  );
};

SummaryIcon.propTypes = {
  active: PropTypes.bool,
};
