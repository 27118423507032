import React, { useEffect, useState,useContext } from "react";
import {
  Modal,
  Typography,
  Button,
  CircularProgress,
  Container,
  Link
} from "@mui/material"; // Assuming you're using Material-UI
import "./DiseaseInfoDialog.css";
import { State } from "../../State";

const apiUrl = process.env.REACT_APP_API_URL;

const sendFetchRequest = (diseaseId, animalType, setDiseaseInfoDescription) => {
  setDiseaseInfoDescription("LOADING");

  fetch(`${apiUrl}/v1/disease`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      animalType, 
      diseaseId,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      setDiseaseInfoDescription(data.description);
    })
    .catch((error) => console.error("Error:", error));
};

export const DiseaseInfoDialog = ({ open, onClose, disease }) => {
  const [diseaseDescription, setDiseaseDescription] = useState("");
  const { animalType } = useContext(State);
  useEffect(() => {
    if (disease && disease.id) {
      sendFetchRequest(disease.id, animalType, setDiseaseDescription);
    }
  }, [disease]);

  return (
    <Modal open={open} onClose={onClose}>
      <Container className="infoDialogContainer">
        <Typography sx={{ fontWeight: "bold", mb: "1em" }}>
          {disease?.name ?? ''}
        </Typography>
        <div className="infoDialogDescriptionBlock">
          {diseaseDescription === "LOADING" ? (
            <CircularProgress />
          ) : (
            <Typography>{diseaseDescription}</Typography>
          )}
        </div>
        {disease?.link && <div className="infoDialogLink">
          <Link  href={disease.link} target="_blank" rel="noopener">
            <Typography sx={{ fontWeight: "bold", mb: "1em" }}>
               Mehr lesen bei petsvetcheck.de
            </Typography>
          </Link>
        </div>}
        <div className="infoDialogButtonBlock">
          <Button variant="contained" color="primary" onClick={onClose}>
            Schließen
          </Button>
        </div>
      </Container>
    </Modal>
  );
};
