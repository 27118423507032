import React, { useContext } from "react";

import {
  IconButton,
  Typography,
  Grid,
  Box,
  LinearProgress,
} from "@mui/material";
import { State } from "../../State";
import HelpIcon from "@mui/icons-material/Help";

export const Disease = (props) => {
  const {
    animalType,
    selectedRegions,
    regionSectionAnswered,
    initialQuestions,
    extraQuestions,
    questionSectionAnswered,
    setNextQuestion,
    setSymptomCandidates,
    setSymptom2diseasesLow,
    setSymptom2diseasesMedium,
    setSymptom2diseasesHigh,
    diseaseCandidates,
    setDiseaseCandidates,
    isDebugMode,
    setSummaryUpdateInProgress,
    topKProbability,
    setTopKProbability,
  } = useContext(State);

  return (
    <>
      <Grid item sm={12} md={9} lg={8} xl={8}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ width: "100%", flexShrink: 0 }}>
            {props.name}
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => {
                if (props.setInfoDialogDisease && props.setInfoDialogOpen)
                  props.setInfoDialogDisease({id: props.id, name: props.name, link: props.link});
                props.setInfoDialogOpen(true);
              }}
            >
              <HelpIcon />
            </IconButton>
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <LinearProgress
              variant="determinate"
              value={props.value * 100}
              sx={{
                width: "5rem",
                height: ".7rem",
                borderRadius: "1rem",
                mr: 1,
              }}
            ></LinearProgress>
            <Typography
              sx={{
                fontSize: "0.7em",
                marginRight: "0.5em",
              }}
            >
              {isDebugMode()
                ? props.value * 100
                : parseFloat((props.value * 100).toFixed(2))}{" "}
              %
            </Typography>
            {props.value < 0.25 ? (
              <Typography variant="caption">
                {" "}
                Schwache Wahrscheinlichkeit
              </Typography>
            ) : props.value < 0.5 ? (
              <Typography variant="caption">
                Mittlere Wahrscheinlichkeit
              </Typography>
            ) : (
              <Typography variant="caption">
                Starke Wahrscheinlichkeit
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};
