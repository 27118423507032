import React, { useContext } from "react";
import "./StartOver.css";
import { Box, Button, Grid, Typography } from "@mui/material";
import { State } from "../../State";
import PropTypes from "prop-types";

export const StartOver = (props) => {
  const { resetState } = useContext(State);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const initState = () => {
    resetState();
    props.renewSections();
    scrollToTop();
  };

  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>
        Möchten sie alle Antworten löschen und von vorne anfangen?
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1.5 }}>
        <Button
          variant="contained"
          sx={{ textTransform: "none", m: 1, maxWidth: "15rem" }}
          fullWidth
          onClick={() => {
            props.setStartOverSectionOpened(false);
          }}
        >
          Nein
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none", m: 1, maxWidth: "15rem" }}
          onClick={() => initState()}
          fullWidth
        >
          Ja
        </Button>
      </Grid>
    </>
  );
};

StartOver.propTypes = {
  renewSections: PropTypes.func.isRequired,
  setStartOverSectionOpened: PropTypes.func.isRequired,
};
