import React, { useContext } from "react";
import { Typography } from "@mui/material";
import "./SummaryLabel.css";
import { State } from "../../State";
import CircularProgress from "@mui/material/CircularProgress";

export const SummaryLabel = () => {
  const { summaryUpdateInProgress } = useContext(State);

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: false ? "bold" : null,
          color: false ? "secondary.main" : "primary.main",
        }}
      >
        <span className="non-selectable">
          Mögliche Erkrankungen{" "}
          {summaryUpdateInProgress && <CircularProgress></CircularProgress>}
        </span>
      </Typography>
    </>
  );
};
