import React, { useContext, useState } from "react";
import "./Symptoms.css";
import { Button, Typography } from "@mui/material";
import { Question } from "../question/Question";
import { State } from "../../State";
import { SymptomInfoDialog } from "../dialog/SymptomInfoDialog";

export const Symptoms = () => {
  const {
    initialQuestions,
    setInitialQuestions,
    setExtraQuestionSectionOpen,
    setQuestionSectionOpen,
    questionSectionAnswered,
    setQuestionSectionAnswered,
    setSummarySectionOpened,
  } = useContext(State);

  const openNextSection = () => {
    setExtraQuestionSectionOpen(true);
    setQuestionSectionOpen(false);
    setQuestionSectionAnswered(true);
    setSummarySectionOpened(true);
  };

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoDialogSymptom, setInfoDialogSymptom] = useState(null);
  const sortedInitialQuestions = Object.entries(initialQuestions).sort(([, a], [, b]) => a.orderNumber - b.orderNumber);

  return (
    <>
      <Typography sx={{ fontWeight: "bold" }}>
        Welche Beschwerden sind Ihnen aufgefallen?
      </Typography>
      {/** You can use "Tooltip"/"popover" component from the library to show info on Hoover/click, just wrap the IconButton inside a Tooltip/popover */}
      {/* <IconButton variant="contained" color="primary" onClick={() => {}}>
        <HelpIcon />
      </IconButton> */}
      <div className="questionsContainer">
        { sortedInitialQuestions.map(([id, { answer, name, link }]) => {
        return (
            <Question
              setInfoDialogSymptom={setInfoDialogSymptom}
              setInfoDialogOpen={setInfoDialogOpen}
              key={id}
              questionName={name}
              questionId={id}
              link={link}
              value={answer}
              setAnswer={(answer) => {
                setInitialQuestions({...initialQuestions, [id]:  {... initialQuestions[id], answer: answer.target.value}});
              }}
            />
          );
        })}
      </div>

      {!questionSectionAnswered && (
        <Button
          onClick={() => {
            // sendInitialRequest();
            openNextSection();
          }}
          variant="contained"
          className="button-26"
          sx={{ textTransform: "none", mt: 2 }}
        >
          Weiter
        </Button>
      )}
      <SymptomInfoDialog
        open={infoDialogOpen}
        symptom={infoDialogSymptom}
        onClose={() => {
          setInfoDialogOpen(false);
        }}
      />
    </>
  );
};
