import React, { useEffect, useState } from "react";
import "./Footer.css";

const VERSION_KEY = 'version';

const clearCacheReload = async () => {
  if ("caches" in window) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((name) => caches.delete(name)));
    window.location.reload();
  }
};

function Footer() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const showVersion =
    localStorage.getItem("show_version") === "true" ? true : false;

  const [version, setVersion] = useState("");
  
  useEffect(() => {
    fetch(`${apiUrl}/v1/version`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setVersion(data.version);

        const localVersion = localStorage.getItem(VERSION_KEY);
        if (!localVersion && data.version) {
          localStorage.setItem(VERSION_KEY, data.version)
          void clearCacheReload()
        } else if (localVersion && data.version && localVersion!==data.version) {
          localStorage.setItem(VERSION_KEY, data.version)
          void clearCacheReload()
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <footer>
      <p>&copy; {2024} Petsvetcheck</p>
      {showVersion && <p>version : {version} </p>}
    </footer>
  );
}

export default Footer;
