import React from "react";
import ExtraQuestionIcon from "@mui/icons-material/Quiz";
import PropTypes from "prop-types";

export const ExtraSymptomIcon = (props) => {
  return (
    <ExtraQuestionIcon
      sx={{
        color: props.active ? "secondary.main" : "primary.main",
        fontSize: "2rem",
      }}
    />
  );
};

ExtraSymptomIcon.propTypes = {
  active: PropTypes.bool,
};
